.header {
    .navbar {
        .navbar-brand {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px 40px;

            .brand-name {
                font-family: $font-family;
                color: #000;
                text-transform: lowercase;
                margin: 0;
                font-weight: bold;
                padding: 5px 15px;
                font-size: 30px;
            }

            img {
                max-height: 50px;
            }
        }
        background: #fff;
        box-shadow: none;
        .profile {
            cursor: pointer;
            .btn {
                &:focus {
                    box-shadow: none;
                }
            }
            .profile-name {
                &.btn.dropdown-toggle {
                    font-size: 12px;
                }
                color: $default-text-color;
                font-weight: 600;
                span {
                    font-weight: 400;
                    color: $label-color;
                }
            }
            .btn {
                &.dropdown-toggle {
                    font-size: 14px;
                    text-transform: capitalize;
                }
            }
            .placeholder {
                border: 2px solid tint($placeholder-color, 60);
                align-self: center;
                max-height: 34px;
                border-radius: 50%;
                margin-left: 0.5em;
            }
        }
    }
}

.search-container {
    display: flex;
    form {
        border: 1px solid tint($placeholder-color, 60);
        border-radius: 4px;
        padding: 0 4px;
        height: 48px;
        // padding: 3px 4px 3px 6px;
        margin: 5px;
        .fd-1 {
            // width: 220px;
            width: 100%;
            position: relative;
            padding-right: 13px;
            label {
                font-size: 10px;
                justify-content: left;
                align-items: left;
                color: $label-color;
            }
            // &:before {
            // 	position: absolute;
            // 	content: '';
            // 	left: -7px;
            // top: 7px;
            // height: 26px;
            // 	border-right: 1px solid tint($placeholder-color, 70);
            // }
            .form-control,
            .btn {
                font-size: 12px;
                padding: 0;
                height: auto;
                border: none;
                width: 100%;
                color: $default-text-color;
                background: transparent;
                &:focus {
                    border: none !important;
                }
            }
        }
        .fd-2 {
            // width: 700px;
            position: relative;

            .form-control,
            .btn {
                font-size: 12px;
                padding: 0;
                height: auto;
                border: none;
                width: 100%;
                color: $default-text-color;
                background: transparent;
                &:focus {
                    border: none !important;
                }
            }
        }
        .search-btn {
            background: $primary-color;
            color: $white-color;
            padding: 7px 11px;
            border-radius: 2px;
            &:hover {
                background: shade($primary-color, 20);
            }
        }
    }
}

.multiselect-native-select {
    margin-right: 0.5rem;
    .btn {
        text-align: left;
        justify-content: left;
        &:focus {
            box-shadow: none;
        }
    }
}
.multiselect-container {
    min-width: 14rem;
    > li {
        &:first-child {
            border-radius: 4px 4px 0 0;
        }
        &:last-child {
            border-radius: 0 0 4px 4px;
        }
        padding: 6px 10px;
        &:hover,
        &.active {
            background-color: tint($placeholder-color, 90);
        }
        text-decoration: none;
        a {
            label {
                font-size: 12px !important;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    label {
        padding: 0 !important;
        justify-content: left;
        font-size: 14px !important;
        input {
            margin-left: 0 !important;
            margin-right: 10px !important;
            margin-bottom: 0 !important;
        }
    }
}

.heading-section {
    padding: 15px 0;
    float: right;
    .display-result {
        letter-spacing: 0;
        font-weight: 400;
        text-transform: none;
        color: $label-color;
        font-size: 14px;
        a {
            cursor: pointer;
            color: $label-color;
            i {
                vertical-align: bottom;
            }
            &:hover {
                color: tint($label-color, 30);
            }
        }
    }
    .call-to-action {
        .btn {
            .rotating {
                margin-right: 6px;
            }
        }
        .date-picker-comp {
            position: relative;
            margin: 0 17px;
            &:before,
            &:after {
                position: absolute;
                content: "";
                top: 50%;
                height: 70%;
                transform: translate(-50%, -50%);
                border-right: 1px solid tint($placeholder-color, 70);
            }
            &:before {
                left: -15px;
            }
            &:after {
                right: -15px;
            }
            label {
                display: inline-block;
                float: left;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #434349;
                margin-bottom: 0;
                color: $label-color;
                font-size: 14px;
                margin-right: 10px;
            }
            .date-picker-wrapper {
                float: right;
                position: relative;
                i {
                    position: absolute;
                    padding: 0;
                    right: 10px;
                    top: 10px;
                    font-size: 12px;
                    color: $label-color;
                    cursor: pointer;
                }
                .form-control {
                    width: 150px;
                    font-size: 14px;
                    padding-right: 30px;
                    height: calc(1.5em + 0.75rem + -1px);
                    margin-right: 0 !important;
                }
            }
        }
        .btn-group {
            margin-left: 15px;
            .or {
                position: relative;
                width: 6px;
                &:before {
                    content: "or";
                    line-height: 24px;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 12px;
                    font-weight: 600;
                    height: 24px;
                    color: $label-color;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    width: 24px;
                    background: $background-color;
                    border-radius: 24px;
                    z-index: 99;
                }
            }
            .btn {
                margin-left: 0;
            }
        }
        .btn {
            margin-left: 15px;
            padding: 0.375rem 1.34rem;
        }
    }
    .mapping-tool-btn {
        margin-left: 15px;
    }
    h5 {
        margin-bottom: 23px;
        margin-top: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $heading-color;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 3px;
        text-transform: uppercase;
        .back-btn {
            background-color: $heading-color;
            i {
                color: $white-color;
            }
        }
    }
}
