// App imports
@import "base/variables";
@import "base/mixins";
@import "base/reset";
@import "base/base";

@import "components/buttons";
@import "components/multiselect";
@import "components/tables";
@import "components/pagination";
@import "components/forms";
@import "components/animation";
@import "components/modal";
@import "components/tabs";
@import "components/accordion";
@import "components/sidebar";
@import "components/skeleton-loader";
@import "components/videoplayer";

@import "layout/header";

/* override browser default */
html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: $font-family;
    font-style: $font-style;
    height: 100vh;
    width: 100vw;
}

/* include border and padding in element width and height */
* {
    box-sizing: border-box;
}

textarea {
    overflow: scroll;
}

/* Mobile devices */
@media only screen and (min-width: 300px) and (max-width: 480px) {
    .header {
        .navbar {
            display: flex;
            justify-content: center;
        }
    }
    .er-content {
        top: 60px !important;
        .title {
            font-size: 22px !important;
        }
        .er-title {
            img {
                width: 45px !important;
                height: 45px !important;
            }
        }
        .er-description {
            font-size: 25px !important;
        }
        .er-description-small {
            font-size: 18px !important;
        }
        .app-buttons {
            .play-store,
            .app-store {
                font-size: 15px !important;
                padding: 10px 35px !important;
            }
        }
    }
    .authentication-container {
        flex-direction: column !important;

        .login-screen-img {
            display: none;
        }

        .login-content {
            padding: 0 !important;
            margin-top: -60%;
            text-align: center;
        }
    }
    .mobile-toggle {
        display: flex !important;
        margin-left: 0% !important;
    }
    .navbar-expand {
        margin-left: 22% !important;
    }
    .content-expand {
        margin-top: -155px !important;
    }
    .sidebar {
        // padding-top: 15px !important;
        width: 0% !important;

        .on-profile {
            display: none !important;
        }
    }
    .sidebar-expand {
        width: 24% !important;
    }
    .sidebar a {
        font-size: 18px;
    }
    .admin-panel-logo {
        width: 45px;
        height: 45px;
    }

    .admin-panel-side-logo {
        display: none !important;
    }
    .admin-text {
        font-weight: 700;
        font-size: 18px;
        margin-top: 8px;
        margin-left: 8px;
        color: $black-color;
    }
    .admin-panel-text {
        display: none !important;
    }
    .expand-div {
        padding: 0 !important;

        .icon-text {
            display: none !important;
        }
    }
    .content-container {
        margin-left: 0% !important;
        margin-top: -120px !important;

        .btn-share {
            display: block !important;
        }

        .content_language {
            display: none !important;
        }

        .content-container__data {
            .item {
                flex: 0 1 calc(100%) !important;
            }
        }

        .drag-file-container {
            .drag-container {
                margin: 7px !important;
                padding: 10px !important;
                border-radius: 20px !important;

                .upload-mobile-view {
                    flex-direction: column;
                    text-align: center;
                }
            }
            .left-margin {
                margin-left: 5px !important;
            }
        }

        .tabs-main-container {
            margin: 120px 10px -20px 10px !important;

            .main-container {
                width: 100% !important;
            }

            .my-content-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 !important;
            }

            .tabs-container {
                justify-content: space-between;
                width: 100%;
                overflow: hidden;

                .filler {
                    display: none;
                }
                .active-tab {
                    margin-left: -18px !important;
                }
                .tab {
                    padding: 5px 5px !important;
                    font-size: 15px !important;
                    .tab-img {
                        height: 17px !important;
                    }
                    .text {
                        margin-right: 20px !important;
                    }
                }
            }
        }

        .container {
            .video-container {
                video {
                    height: 65vh !important;
                    width: 100vw;
                }
                .video-controls {
                    bottom: -35% !important;
                }
            }
        }

        // .content-container__data {
        //     margin-left: -10px !important;
        // }

        .schedule-info {
            font-size: 15px !important;
            padding-left: 10px !important;
        }

        .container {
            .video-wrapper {
                .controls {
                    bottom: 0px !important;
                    padding: 5px !important;
                }
            }
        }

        .profile-container {
            .profile-container__data {
                padding: 0 !important;
                margin-left: 25px;
                .details {
                    margin-left: 70px;
                }
            }
        }

        .notification-section-mobile {
            display: block !important;
        }

        .notification-section {
            display: none;
        }

        .notifications-count {
            padding: 100px 0 0 0 !important;
            flex-direction: column;

            .btn-sync {
                margin-top: 20px;
            }
        }

        .panel-container {
            margin: 0 !important;
            margin-top: 20px !important;
            .left-container {
                padding: 0 !important;
                max-width: 100% !important;
            }
        }
    }
    .no-posts {
        // margin-left: 15% !important;
        left: 0% !important;
        top: 45% !important;
        width: 100%;

        img {
            height: 150px !important;
        }
        p {
            font-size: 15px !important;
        }
    }

    .notification-container {
        .request-access {
            display: none;
        }
    }

    .btn-upload {
        display: none;
    }

    .btn-upload-mobile {
        display: block !important;
        border: 0 !important;
        padding: 8px 15px !important;
        border-radius: 50% !important;
        font-size: 25px !important;
        color: $white-color !important;
        // background: linear-gradient(270deg, #31e981 0%, #0c6e37 100%) !important;
        background: $primary-color !important;
        box-shadow: 0px 12px 14px rgb(14 154 167 / 16%) !important;
        position: fixed !important;
        bottom: 20px !important;
        right: 20px !important;
        z-index: 1 !important;
    }

    .btn-refresh {
        top: 90% !important;
        position: absolute !important;
        z-index: 1;
    }

    .btn-scroll {
        display: none;
    }

    .feedback-dashboard-wrapper {
        display: none !important;
    }
}

/* Ipads, Tablets */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .er-content {
        top: 80px !important;
        .title {
            font-size: 22px !important;
        }
        .er-title {
            img {
                width: 45px !important;
                height: 45px !important;
            }
        }
        .er-description {
            font-size: 25px !important;
        }
        .er-description-small {
            font-size: 18px !important;
        }
        .app-buttons {
            .play-store,
            .app-store {
                font-size: 15px !important;
                padding: 10px 35px !important;
            }
        }
    }
    .admin-panel-logo {
        width: 80px;
    }
    .sidebar {
        width: 11% !important;
    }
    .sidebar-expand {
        width: 24% !important;
    }
    .content-container {
        margin-left: 10% !important;
    }
    .expand-content {
        margin-left: 23% !important;
    }
    .profile-container {
        .profile-container__data {
            padding: 0 !important;
            margin-left: 25px;
            .details {
                margin-left: 70px;
            }
        }
    }
}

/* Small screens, laptops */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .er-content {
        top: 100px !important;
        .er-description {
            font-size: 28px !important;
        }
        .er-description-small {
            font-size: 20px !important;
        }
    }
    .authentication-container {
        flex-direction: column !important;
        img {
            // width: 600px !important;
            margin-top: 200px !important;
            margin-left: 200px !important;
        }
    }
    .sidebar {
        width: 11% !important;
    }
}

/* Ipad Air */
@media only screen and (min-width: 820px) and (max-width: 1180px) {
    .er-content {
        top: 100px !important;
        .er-description {
            font-size: 28px !important;
        }
        .er-description-small {
            font-size: 20px !important;
        }
    }
    .authentication-container {
        flex-direction: column !important;
        img {
            margin-top: 800px !important;
            margin-left: -80px !important;
        }
    }
    .admin-panel-logo {
        width: 80px;
    }
    .sidebar {
        width: 11% !important;
    }
    .sidebar-expand {
        width: 24% !important;
    }
    .content-container {
        margin-left: 10% !important;
    }
    .expand-content {
        margin-left: 23% !important;
    }
    .no-posts {
        margin-top: -25% !important;
        img {
            height: 200px !important;
        }
        p {
            font-size: 15px !important;
        }
    }
    .profile-container {
        .profile-container__data {
            padding: 0 !important;
        }
    }
}

/* Desktops, large screens */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .er-content {
        top: 150px !important;
        .er-description {
            font-size: 30px !important;
        }
        .er-description-small {
            font-size: 22px !important;
        }
    }
    .authentication-container {
        flex-direction: column !important;
        // img {
        //     width: 600px !important;
        //     margin-top: 200px;
        // }
    }
}

/* Extra large screens, TV */
@media only screen and (min-width: 1201px) {
    .er-content {
        top: 200px !important;
    }
}

/* Macbook 13inch */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .sidebar {
        // padding-top: 15px !important;
        width: 6% !important;
    }
    .sidebar-expand {
        width: 14% !important;
    }
    .container {
        .video-container {
            height: 37vh !important;
            video {
                height: 70vh !important;
                margin-top: 40%;
            }
            .video-controls {
                bottom: -74% !important;
            }
        }
    }

    .tabs-container {
        .tab {
            padding: 6px 35px;
        }
    }
}
