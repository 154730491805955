.btn {
    font-size: 14px;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    height: 35px;
    width: 35px;
    color: $label-color;
    &:hover,
    &.active {
        color: $primary-color;
    }
    &:focus {
        box-shadow: none;
    }
}

.btn-link-with-icon {
    color: $label-color;
    i {
        color: $label-color;
        font-size: 12px;
        margin-right: 6px;
    }
    &:hover,
    &:focus {
        text-decoration: none;
        color: $primary-color;
        box-shadow: none;
        i {
            color: $primary-color;
        }
    }
}

.btn-primary {
    box-shadow: none !important;
    background: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color !important;
    width: max-content;
}

.btn-success {
    box-shadow: none !important;
}

.btn-secondary {
    box-shadow: none !important;
    color: $label-color !important;
    background: transparent;
    // border: none !important;

    &:hover {
        color: $label-color !important;
        background: transparent;
    }
}

.btn-search {
    width: 100%;
}

.primary-btn {
    background: $primary-color;
    border-radius: 10px;
    color: $white-color;
    padding: 10px 25px;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    text-transform: uppercase;
}

.forgot-pwd {
    font-size: 15px;
    line-height: 22px;
    text-decoration-line: underline;
    a {
        color: $primary-color !important;
    }
}

.fake-btn {
    width: 100px;
    height: 30px;
    border-radius: 5px;
}

.btn-upload {
    padding: 0px 40px;
    background: $white-color !important;
    border: none;
    color: $black-color !important;
    font-size: 15px;
    box-shadow: 0px 13px 26px rgb(0 0 0 / 15%) !important;
    height: 45px;
    text-transform: capitalize;

    &:hover {
        background: $primary-color !important;
        color: $white-color !important;
    }
}

.btn-delete {
    color: $black-color;
    background-color: $background;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-weight: 500;
    &:hover {
        background-color: $red-color;
        color: $white-color;
    }
}

.btn-share {
    color: $black-color;
    background-color: $background;
    padding: 5px 25px;
    font-size: 15px;
    font-weight: 500;
    &:hover {
        background-color: $red-color;
        color: $white-color;
    }
}

.btn-yes {
    background-color: $red-color;
    color: $white-color;
    padding: 5px 35px;
    font-size: 15px;
    font-weight: 500;
    &:hover {
        background-color: $red-color;
        color: $white-color;
    }
}

.btn-no {
    color: $white-color;
    background-color: $primary-color;
    color: $white-color;
    padding: 5px 35px;
    font-size: 15px;
    font-weight: 500;
    &:hover {
        background-color: $primary-color;
        color: $white-color;
    }
}

.close-btn {
    color: $white-color;
    font-size: 35px;
    font-weight: 300;
    text-shadow: none;
}
.btn-upload-mobile {
    display: none;
}
button:focus {
    outline: 0;
}

.notification-count {
    background: $primary-color;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    color: $white-color;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
}

.btn-edit {
    padding: 5px 35px;
}

.on-profile {
    position: absolute;
    bottom: 25%;
    right: 10%;
}

.btn-refresh {
    display: flex;
    justify-content: center;
    position: relative;
    background: $white-color;
    color: $black-color;
    border: none;
    border-radius: 20px;
    padding: 5px 25px;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
        color: $white-color;
        background: $primary-color;
    }
}

.btn-scroll {
    background: $white-color;
    border: none;
    width: 50px;
    height: 45px;
    color: $primary-color;
    border-radius: 50%;
}

.btn-sync {
    background: $white-color;
    color: $black-color;
    border: none;
    border-radius: 20px;
    padding: 5px 25px;
}

.btn-add-more {
    border: 0 !important;
    padding: 5px 10px !important;
    border-radius: 50% !important;
    font-size: 15px !important;
    color: #fff !important;
    background: #0c6e37 !important;
    box-shadow: 0px 12px 14px rgb(14 154 167 / 16%) !important;
}
